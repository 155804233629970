import ActionRepository from "./repositories/actionRepository";
import UsersRepository from "./repositories/usersRepository";
import CompanyRepository from "./repositories/companyRepository";
import AppsRepository from "./repositories/applicationRepository";
import TagDataRepository from "./repositories/tagDataRepository";
import TagRepository from "./repositories/tagRepository";
import AlarmRepository from "./repositories/alarmRepository";
import ScriptRepository from "./repositories/scriptRepository";
import ReportRepository from "./repositories/reportRepository";
import DashboardRepository from "./repositories/dashboardRepository";
import ModuleRepository from "./repositories/moduleRepository";
import LogRepository from "./repositories/logRepository";
import NotificationRepository from "./repositories/notificationRepository";
import SignalTestRepository from "./repositories/signalTestRepository";
import LicenseRepository from "./repositories/licenseRepository";
import GatewayRepository from "./repositories/gatewayRepository";
import IntegrationsRepository from "./repositories/integrationsRepository";
import DownlinkRepository from "./repositories/downlinkRepository";
import RoleRepository from "./repositories/roleRepository";

const repositories = {
  actions: ActionRepository,
  users: UsersRepository,
  companies: CompanyRepository,
  applications: AppsRepository,
  tagdata: TagDataRepository,
  tag: TagRepository,
  alarm: AlarmRepository,
  script: ScriptRepository,
  report: ReportRepository,
  dashboard: DashboardRepository,
  module: ModuleRepository,
  log: LogRepository,
  notification: NotificationRepository,
  signalTest: SignalTestRepository,
  license: LicenseRepository,
  gateways: GatewayRepository,
  integrations: IntegrationsRepository,
  downlinks: DownlinkRepository,
  roles: RoleRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
